exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-immediate-care-index-jsx": () => import("./../../../src/pages/immediate-care/index.jsx" /* webpackChunkName: "component---src-pages-immediate-care-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-motor-vehicle-accidents-index-jsx": () => import("./../../../src/pages/motor-vehicle-accidents/index.jsx" /* webpackChunkName: "component---src-pages-motor-vehicle-accidents-index-jsx" */),
  "component---src-pages-patient-forms-index-jsx": () => import("./../../../src/pages/patient-forms/index.jsx" /* webpackChunkName: "component---src-pages-patient-forms-index-jsx" */),
  "component---src-pages-pay-online-index-jsx": () => import("./../../../src/pages/pay-online/index.jsx" /* webpackChunkName: "component---src-pages-pay-online-index-jsx" */),
  "component---src-pages-personal-injury-index-jsx": () => import("./../../../src/pages/personal-injury/index.jsx" /* webpackChunkName: "component---src-pages-personal-injury-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-services-in-house-blood-work-index-jsx": () => import("./../../../src/pages/services/in-house-blood-work/index.jsx" /* webpackChunkName: "component---src-pages-services-in-house-blood-work-index-jsx" */),
  "component---src-pages-services-injury-index-jsx": () => import("./../../../src/pages/services/injury/index.jsx" /* webpackChunkName: "component---src-pages-services-injury-index-jsx" */),
  "component---src-pages-services-internal-medicine-index-jsx": () => import("./../../../src/pages/services/internal-medicine/index.jsx" /* webpackChunkName: "component---src-pages-services-internal-medicine-index-jsx" */),
  "component---src-pages-services-orthopedic-index-jsx": () => import("./../../../src/pages/services/orthopedic/index.jsx" /* webpackChunkName: "component---src-pages-services-orthopedic-index-jsx" */),
  "component---src-pages-services-pain-management-index-jsx": () => import("./../../../src/pages/services/pain-management/index.jsx" /* webpackChunkName: "component---src-pages-services-pain-management-index-jsx" */),
  "component---src-pages-services-physical-therapy-index-jsx": () => import("./../../../src/pages/services/physical-therapy/index.jsx" /* webpackChunkName: "component---src-pages-services-physical-therapy-index-jsx" */),
  "component---src-pages-services-plasma-injections-index-jsx": () => import("./../../../src/pages/services/plasma-injections/index.jsx" /* webpackChunkName: "component---src-pages-services-plasma-injections-index-jsx" */),
  "component---src-pages-services-school-work-physicals-index-jsx": () => import("./../../../src/pages/services/school-work-physicals/index.jsx" /* webpackChunkName: "component---src-pages-services-school-work-physicals-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-b-12-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/b-12/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-b-12-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-botox-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/botox/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-botox-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-derma-roller-microneedling-session-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/derma-roller-microneedling-session/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-derma-roller-microneedling-session-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-iv-drips-therapy-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/iv-drips-therapy/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-iv-drips-therapy-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-platelet-rich-plasma-facial-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/platelet-rich-plasma-facial/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-platelet-rich-plasma-facial-index-jsx" */),
  "component---src-pages-services-wellness-and-cosmetic-prp-for-hair-loss-index-jsx": () => import("./../../../src/pages/services/wellness-and-cosmetic/prp-for-hair-loss/index.jsx" /* webpackChunkName: "component---src-pages-services-wellness-and-cosmetic-prp-for-hair-loss-index-jsx" */),
  "component---src-pages-services-x-rays-index-jsx": () => import("./../../../src/pages/services/x-rays/index.jsx" /* webpackChunkName: "component---src-pages-services-x-rays-index-jsx" */),
  "component---src-pages-telemedicine-index-jsx": () => import("./../../../src/pages/telemedicine/index.jsx" /* webpackChunkName: "component---src-pages-telemedicine-index-jsx" */),
  "component---src-pages-workers-comp-index-jsx": () => import("./../../../src/pages/workers-comp/index.jsx" /* webpackChunkName: "component---src-pages-workers-comp-index-jsx" */),
  "component---templates-landing-page-one-jsx": () => import("./../../../templates/landing-page-one.jsx" /* webpackChunkName: "component---templates-landing-page-one-jsx" */)
}

